import React from 'react'
import img from './contact.jpg'
import AOS from 'aos';
import "aos/dist/aos.css";
export default function Contact() {
      AOS.init();
  return (
//    <section class="min-h-screen bg-white dark:bg-white">
     
//    

//         <div class="lg:flex lg:items-center sh lg:-mx-10">
//             <div class="lg:w-1/2 lg:mx-10">
//                 <h1 class="text-2xl font-semibold text-black capitalize dark:text-black lg:text-3xl">Let’s talk</h1>

//                 <h2 class="mt-4 text-lg text-black dark:text-black">
//                     Ask us everything and we would love
//                     to hear from you
//                 </h2>

//                 <form class="mt-12">
//                     <div class="-mx-2 md:items-center md:flex">
//                         <div class="flex-1 px-2">
//                             <label class="block mb-2 text-sm text-black dark:text-black">Full Name</label>
//                             <input type="text" placeholder="Enter Your Name" class="block w-full px-5 py-3 mt-2 text-black placeholder-black bg-white border border-gray-200 rounded-md dark:placeholder-black dark:bg-white dark:text-gray-300 dark:border-gray-200 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
//                         </div>

//                         <div class="flex-1 px-2 mt-4 md:mt-0">
//                             <label class="block mb-2 text-sm text-black dark:text-black">Email address</label>
//                             <input type="email" placeholder="Enter Your Email" class="block w-full px-5 py-3 mt-2 text-black placeholder-black bg-white border border-gray-200 rounded-md dark:placeholder-black dark:bg-white dark:text-gray-300 dark:border-gray-200 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"/>
//                         </div>
//                     </div>
//                      <div class="w-full mt-4">
//                         <label class="block mb-2 text-sm text-black dark:text-black">Mobile Number</label>
//                         <input class="block w-full px-5 py-3 mt-2 text-black placeholder-black bg-white border border-gray-200 rounded-md dark:placeholder-black dark:bg-white dark:text-gray-300 dark:border-gray-200 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" placeholder='Enter Your Mobile Number'></input>
//                     </div>

//                     <div class="w-full mt-4">
//                         <label class="block mb-2 text-sm text-black dark:text-black">Message</label>
//                         <textarea class="block w-full h-32 px-5 py-3 mt-2 text-black placeholder-black bg-white border border-gray-200 rounded-md md:h-56 dark:placeholder-gray-600 dark:bg-white dark:text-gray-300 dark:border-gray-200 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" placeholder="Message"></textarea>
//                     </div>

//                     <button class="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:blue-500 focus:outline-none focus:ring focus:blue-500 focus:ring-opacity-50">
//                         Send Message
//                     </button>
//                 </form>
//             </div>

//             <div class="mt-12 lg:flex lg:mt-0 lg:flex-col lg:items-center lg:w-1/2 lg:mx-10">
//                 <img data-aos="zoom-in" class="hidden object-cover mx-auto rounded-full lg:block shrink-0 w-96 h-96" src={img} alt=""/>

//                 <div class="mt-6 space-y-8 md:mt-8">
//                     <p class="flex items-start -mx-2">
                      

//                         <span class="mx-2 text-black truncate w-72 dark:text-black">
//                           Kenmerk Softwares,Dhottappankulam<br/> Sulthan Bathery,Wayanad
//                           <br/>Mob: 7012786273
//                         </span>
//                     </p>

                  
//                     <p class="flex items-start -mx-2">
                       

//                         <span class="mx-2 text-black truncate w-72 dark:text-black">kenmerksoftwares@gmail.com</span>
//                     </p>
//                 </div>

               
            
//             </div>
//         </div>
//     </div>
// </section>
   <section class="min-h-screen bg-white dark:bg-white">
     
    <div class="container px-6 py-10 mx-auto">
         <div class="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
    <h2 class="text-2xl font-bold md:text-4xl md:leading-tight dark:text-black">Contact Us</h2>
    <p class="mt-1 text-black dark:text-black">Expanding possibilities of better tomorrow</p>
  </div>

        <div class="lg:flex lg:items-center sh lg:-mx-10">
            <div class="lg:w-1/2 lg:mx-10">
                <h1 class="text-2xl font-semibold text-black capitalize dark:text-black lg:text-3xl">Let’s talk</h1>

                <h2 class="mt-4 text-lg text-black dark:text-black">
                    Ask us everything and we would love
                    to hear from you
                </h2>

                <form class="mt-12">
                    <div class="-mx-2 md:items-center md:flex">
                        <div class="flex-1 px-2">
                            <label class="block mb-2 text-sm text-black dark:text-black">Full Name</label>
                            <input type="text" placeholder="Enter Your Name" class="block w-full px-5 py-3 mt-2 text-black placeholder-black bg-white border border-gray-200 rounded-md dark:placeholder-black dark:bg-white dark:text-gray-300 dark:border-gray-200 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                        </div>

                        <div class="flex-1 px-2 mt-4 md:mt-0">
                            <label class="block mb-2 text-sm text-black dark:text-black">Email address</label>
                            <input type="email" placeholder="Enter Your Email" class="block w-full px-5 py-3 mt-2 text-black placeholder-black bg-white border border-gray-200 rounded-md dark:placeholder-black dark:bg-white dark:text-gray-300 dark:border-gray-200 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                    </div>
                     <div class="w-full mt-4">
                        <label class="block mb-2 text-sm text-black dark:text-black">Mobile Number</label>
                        <input class="block w-full px-5 py-3 mt-2 text-black placeholder-black bg-white border border-gray-200 rounded-md dark:placeholder-black dark:bg-white dark:text-gray-300 dark:border-gray-200 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" placeholder='Enter Your Mobile Number'></input>
                    </div>

                    <div class="w-full mt-4">
                        <label class="block mb-2 text-sm text-black dark:text-black">Message</label>
                        <textarea class="block w-full h-32 px-5 py-3 mt-2 text-black placeholder-black bg-white border border-gray-200 rounded-md md:h-56 dark:placeholder-gray-600 dark:bg-white dark:text-gray-300 dark:border-gray-200 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" placeholder="Message"></textarea>
                    </div>

                    <button class="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:blue-500 focus:outline-none focus:ring focus:blue-500 focus:ring-opacity-50">
                        Send Message
                    </button>
                </form>
            </div>

            <div class="mt-12 lg:flex lg:mt-0 lg:flex-col lg:items-center lg:w-1/2 lg:mx-10">
                <img data-aos="zoom-in" class="hidden object-cover mx-auto rounded-full lg:block shrink-0 w-96 h-96" src={img} alt=""/>

                <div class="mt-6 space-y-8 md:mt-8">
                    <p class="flex items-start -mx-2">
                      

                        <span class="mx-2 text-black truncate w-72 dark:text-black">
                          Kenmerk Softwares,Dhottappankulam<br/> Sulthan Bathery,Wayanad
                          <br/>Mob: 7012786273
                        </span>
                    </p>

                  
                    <p class="flex items-start -mx-2">
                       

                        <span class="mx-2 text-black truncate w-72 dark:text-black">kenmerksoftwares@gmail.com</span>
                    </p>
                </div>

               
            
            </div>
        </div>
    </div>
</section>



  )
}
