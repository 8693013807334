import React from 'react'
import img from './Gyp Proj.png'
import img1 from './Teach Bay.png'
import AOS from 'aos';
export default function Pro() {
   AOS.init();
  return (
   <div className='bg-[#2f89fc]'>
 
<div  class="max-w-[85rem] bg-[#2f89fc]  px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">

  <div class="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
    <h2 class="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">Our Products</h2>
    <p class="mt-1 text-white dark:text-white">Expanding possibilities of better tomorrow</p>
  </div>

  <div data-aos="zoom-in" class="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">

 <a  class="group  rounded-xl p-5 transition-all dark:hover:bg-transparent" href="#">
      <div class="aspect-w-16 aspect-h-10">
        <img class="w-full object-cover rounded-xl" src={img}/>
      </div>
      <h3 class="mt-5 text-3xl text-white dark:text-white dark:group-hover:text-white">
        Divine Connect
      </h3>
      <h3 class="mt-5 text-xl text-white dark:text-white dark:group-hover:text-white">
        Divine Connect, the ultimate solution for seamless church administration. With our beautifully designed and user-friendly application, we aim to simplify and enhance the way your church manages its operations. Whether you're a small community church or a large religious organization,Divine Connect, is tailored to meet all your administrative needs.
      </h3>
      <p class="mt-3 inline-flex items-center gap-x-2 text-sm font-semibold text-white dark:text-white">
        Learn more
        
      </p>
    </a>
 
   <a class="group  rounded-xl p-5 transition-all dark:hover:bg-transparent" href="#">
      <div class="aspect-w-16 aspect-h-10">
        <img class="w-full object-cover rounded-xl" src={img}/>
      </div>
       <h3 class="mt-5 text-3xl text-white dark:text-white dark:group-hover:text-white">
        Teach Bay
      </h3>
      <h3 class="mt-5 text-xl text-white dark:text-white dark:group-hover:text-white">
        Teach Bay, the comprehensive educational institution administration application designed to streamline and elevate the management of schools, colleges, and universities. With its elegant and intuitive interface, Teach Bay empowers educational institutions to enhance efficiency, communication, and collaboration, creating a conducive environment for academic excellence.
      </h3>
      <p class="mt-3 inline-flex items-center gap-x-2 text-sm font-semibold text-white dark:text-white">
        Learn more
       
      </p>
    </a>


 <a class="group  rounded-xl p-5 transition-all dark:hover:bg-transparent" href="#">
      <div class="aspect-w-16 aspect-h-10">
        <img class="w-full object-cover rounded-xl" src={img}/>
      </div>
       <h3 class="mt-5 text-3xl text-white dark:text-white dark:group-hover:text-white">
      Fit Hub
      </h3>
      <h3 class="mt-5 text-xl text-white dark:text-white dark:group-hover:text-white">
      FitHub, the ultimate gym administration application designed to revolutionize the way you manage your fitness center. With its sleek and intuitive interface, FitHub empowers gym owners, managers, and trainers to streamline their operations and provide an exceptional experience for their members.
      </h3>
      <p class="mt-3 inline-flex items-center gap-x-2 text-sm font-semibold text-white dark:text-white">
        Learn more
       
      </p>
    </a>
 
  </div>

</div>

   </div>

  )
}
