import React from 'react'

import Ban from '../../components/Ban'
import About from '../../components/About'
import Skill from '../../components/Skill'
import Work  from '../../components/Works'
import Works  from '../../components/Work'
import Pro  from '../../components/Pro'
import Testimonial from '../../components/Testimonial'
import Contact  from '../../components/Contact'
export default function Home() {
  return (
    <div>
           <Ban />
      <About />
      
      <Skill />
       <Works />
       <Pro />
      <Work />
      
     
      <Testimonial />
      <Contact />
      
    </div>
  )
}
