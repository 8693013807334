import React from 'react'
import img from './imageclient/bunana.5d83d9caf331ba0dc6a1.png'
import img1 from './imageclient/iota.png'
import img2 from './imageclient/psc.webp'
import img3 from './imageclient/lom.png'
import img4 from './imageclient/smallelo.png'
import AOS from 'aos';
import "aos/dist/aos.css";
import icn from'./icons8-next.gif'
export default function Works() {
     AOS.init();
  return (
<div>
 
<div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">

 <div class="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
    <h2 class="text-2xl font-bold md:text-4xl md:leading-tight dark:text-black">Our clients</h2>
    <p class="mt-1 text-black dark:text-black">A better velocity to the business</p>
  </div>


  <div class="grid grid-cols-2 sm:grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-3 lg:gap-6">
    
    <div class="p-4 md:p-7 bg-gray-100 rounded-lg dark:bg-gray-100">
    <a href='/Iota '>  <img src={img1} class="py-3 lg:py-5 w-16 h-auto md:w-20 lg:w-24 mx-auto text-gray-800 dark:text-gray-200" /></a>
    
 
{/* <img class='mx-auto'  width="40" height="40" src="https://img.icons8.com/dotty/80/circled-right.png" alt="circled-right"/> */}
<a href='/Iota '><p class="mt-3 mx-auto underline text-center items-center gap-x-2 text-sm font-semibold text-black dark:text-black">
        Learn more.. 
      </p></a>  
      
   
    
    </div> 
  
    

     <div class="p-4 md:p-7 bg-gray-100 rounded-lg dark:bg-gray-100">
    <a href='/bunana'> <img  src={img} class="py-3 lg:py-5 w-16 h-auto md:w-20 lg:w-24 mx-auto text-gray-800 dark:text-gray-200" /></a> 
     <a href='/bunana'><p  class="mt-3 underline mx-auto text-center items-center gap-x-2 text-sm font-semibold text-black dark:text-black">
        Learn more..
       
      </p></a>
    </div>
     <div class="p-4 md:p-7 bg-gray-100 rounded-lg dark:bg-gray-100">
   <a href='/Pscrankbook '> <img  src={img2} class="py-3 lg:py-5 w-16 h-auto md:w-20 lg:w-24 mx-auto text-gray-800 dark:text-gray-200" /></a> 
   <a href='/Pscrankbook '> <p href='/Pscrankbook ' class="mt-3 underline mx-auto text-center items-center gap-x-2 text-sm font-semibold text-black dark:text-black">
        Learn more..
       
      </p></a>
    </div>

     <div class="p-4 md:p-7 bg-gray-100 rounded-lg dark:bg-gray-100">
     <a href='/ssf '> <img src={img3} class="py-3 lg:py-5 w-16 h-auto md:w-20 lg:w-24 mx-auto text-gray-800 dark:text-gray-200" /></a>
     <a href='/ssf '><p href='/ssf ' class="mt-3 underline  mx-auto text-center items-center gap-x-2 text-sm font-semibold text-black dark:text-black">
        Learn more..
       
      </p></a>
    </div>

     <div class="p-4 md:p-7 bg-gray-100 rounded-lg dark:bg-gray-100">
      <a href='/elora '> <img src={img4} class="py-3 lg:py-5 w-16 h-auto md:w-20 lg:w-24 mx-auto text-gray-800 dark:text-gray-200" /></a> 
    <a href='/elora '><p  href='/elora ' class="mt-3 underline mx-auto text-center items-center gap-x-2 text-sm font-semibold text-black dark:text-black">
        Learn more..
       
      </p></a>
    </div>
  
  </div>

</div>

</div>

  )
}
