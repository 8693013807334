import React from "react";
import Navbar from "./components/Navbar";

import Footer from "./components/Footer";
import About from "./components/About";
import Work  from "./components/Work";
import Works from "./components/Works";
import Pro from "./components/Pro";
import Contact from "./components/Contact";
import Product from "./components/Product art/Product";
import Productpsc from "./components/Product art/Productpsc";
import Productiota from "./components/Product art/Productiota";
import Productelora from "./components/Product art/Productelora";
import Productssf from "./components/Product art/Productssf";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
const App = () => {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route index element={<Home/>} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Work/>} />
          <Route path="/Products" element={<Pro/>} />
          <Route path="/Works" element={<Works/>} />
          <Route path="/Contact" element={<Contact/>} />
          <Route path="/bunana" element={<Product />} />
          <Route path="/Pscrankbook" element={<Productpsc  />} />
          <Route path="/Iota" element={< Productiota />} />
          <Route path="/elora" element={< Productelora />} />
          <Route path="/ssf" element={< Productssf />} />
      </Routes>

   
      {/* <Product /> */}
      <Footer />
    </div>
  );
};

export default App;
