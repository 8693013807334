import React, { useState } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';
import ai from './ai2.jpg'
import './wave.css'
import './wave.css'
function Ban() {

  return (
<section id='home' class="pt-2 bg-white sm:pt-2">

        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
                <div>
                  <div data-aos="zoom-in" class="mr-auto place-self-center lg:col-span-7">
            <h1
                class="max-w-2xl mb-4 text-2xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-black">
                Kenmerk  <br/>
                <span style={{color:'#0c81f6'}}>Listening to you, and answering with software</span>
            </h1>

            <p class="max-w-2xl mb-6 font-light text-black lg:mb-8 md:text-lg lg:text-xl dark:text-black ">
              We develop innovative and creative products and services that provide total communication and information solutions
            </p>
             </div>

                    <p data-aos="zoom-in" class="mt-8 text-base text-black sm:text-xl">Powering your business with innovative software solutions.</p>

                    <div class="mt-10 sm:flex sm:items-center sm:space-x-8">
                        {/* <a href="#" title="" class="inline-flex items-center justify-center px-10 py-4 text-base font-semibold text-white transition-all duration-200 bg-orange-500 hover:bg-orange-600 focus:bg-orange-600" role="button"> Start exploring </a>

                        <a href="#" title="" class="inline-flex items-center mt-6 text-base font-semibold transition-all duration-200 sm:mt-0 hover:opacity-80">
                            <svg class="w-10 h-10 mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path fill="#F97316" stroke="#F97316" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            Watch video
                        </a> */}
                    </div>
                </div>

                <div data-aos="zoom-in">
                    <img class="w-full" src={ai} alt="" />
                </div>
            </div>
            
        </div>

    <div class="floating_btn">
    <a target="_blank" href="https://wa.me/+917012786273">
      <div class="contact_icon">
        <img width="35" height="35" src="https://img.icons8.com/fluency/35/whatsapp.png" alt="whatsapp" class="fa fa-whatsapp my-float"></img>
      </div>
    </a>
    <p class="text_icon">Talk to us?</p>
  </div>
  
</section>


    
  );
}

export default Ban;