import React from 'react'
import AOS from 'aos';

export default function Work() {
     AOS.init();
  return (

                                        <div  class="py-16 ">
  <div class="xl:container m-auto space-y-16 px-6 text-black md:px-12">
    <div>
      <h2  class="mt-4 text-2xl font-bold text-black dark:text-black md:text-4xl">
        We try our best, and we make sure we deliver  <br class="sm:block" hidden />
        the best
      </h2>
    </div>
    <div data-aos="fade-down"
     data-aos-easing="linear"
      class="md:-mx-8 mt-16 grid gap-8 lg:gap-0 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      <div data-aos="fade-down"
     data-aos-easing="linear"
    class="group relative p-8 rounded-3xl bg-gray-300/10 dark:border-blue-500 dark:bg-white dark:lg:bg-transparent border border-blue-500 lg:border-transparent hover:border-gray-100 dark:shadow-none dark:hover:border-blue-500 dark:hover:bg-gray-300 shadow-2xl shadow-gray-300/10 lg:shadow-transparent lg:hover:shadow-gray-100 sm:gap-8 sm:flex transition duration-300">
        <div  class="relative">
          <img
          
            class="w-10"
             src="https://img.icons8.com/external-flaticons-flat-flat-icons/64/external-mobile-app-gig-economy-flaticons-flat-flat-icons-2.png" alt="external-mobile-app-gig-economy-flaticons-flat-flat-icons-2"
            width="512"
            height="512"
          />
          <h3 class="mt-8 mb-4 text-2xl font-semibold text-gray-800 transition dark:text-black">
        App Development
          </h3>
          <p class="text-black dark:text-black">
           We have expertise in multifarious mobile apps development that will be helpfull in propelling the business in the online market.
          </p>
        </div>
      </div>
      <div data-aos="fade-down"
     data-aos-easing="linear"
  class="group relative p-8 rounded-3xl bg-gray-300/10 dark:border-blue-500 dark:bg-white dark:lg:bg-transparent border border-blue-500 lg:border-transparent hover:border-gray-100 dark:shadow-none dark:hover:border-blue-500 dark:hover:bg-gray-300 shadow-2xl shadow-gray-300/10 lg:shadow-transparent lg:hover:shadow-gray-100 sm:gap-8 sm:flex transition duration-300">
        <div class="relative">
          <img
             src="https://img.icons8.com/external-flatarticons-blue-flatarticons/65/external-web-web-hosting-flatarticons-blue-flatarticons-2.png" alt="external-web-web-hosting-flatarticons-blue-flatarticons-2"
            class="w-10"
            width="512"
            height="512"
          
          />
          <h3 class="mt-8 mb-4 text-2xl font-semibold text-gray-800 transition dark:text-black">
            Web Development
          </h3>
          <p class="text-black dark:text-black0">
          We take care of every aspect of website design from programming, copywriting and graphic design to complete development of your website.
          </p>
        </div>
      </div>
      <div  data-aos="fade-down"
     data-aos-easing="linear"
     class="group relative p-8 rounded-3xl bg-gray-300/10 dark:border-blue-500 dark:bg-white dark:lg:bg-transparent border border-blue-500 lg:border-transparent hover:border-gray-100 dark:shadow-none dark:hover:border-blue-500 dark:hover:bg-gray-300 shadow-2xl shadow-gray-300/10 lg:shadow-transparent lg:hover:shadow-gray-100 sm:gap-8 sm:flex transition duration-300">
        <div class="relative">
          <img
           src="https://img.icons8.com/external-flaticons-flat-flat-icons/64/external-software-agile-flaticons-flat-flat-icons-2.png" alt="external-software-agile-flaticons-flat-flat-icons-2"
            class="w-10"
            width="512"
            height="512"
            
          />
          <h3 class="mt-8 mb-4 text-2xl font-semibold text-gray-800 transition dark:text-black">
            Custom Applications
          </h3>
          <p class="text-black dark:text-black">
         We have the best team to develop Custom Applications according to the requirement of the customers.
          </p>
        </div>
      </div>
      <div  data-aos="fade-down"
     data-aos-easing="linear"
     class="group relative p-8 rounded-3xl bg-gray-300/10 dark:border-blue-500 dark:bg-white dark:lg:bg-transparent border border-blue-500 lg:border-transparent hover:border-gray-100 dark:shadow-none dark:hover:border-blue-500 dark:hover:bg-gray-300 shadow-2xl shadow-gray-300/10 lg:shadow-transparent lg:hover:shadow-gray-100 sm:gap-8 sm:flex transition duration-300">
        <div class="relative">
          <img
            src="https://img.icons8.com/external-goofy-flat-kerismaker/96/external-Digital-Marketing-marketing-goofy-flat-kerismaker.png" alt="external-Digital-Marketing-marketing-goofy-flat-kerismaker"
            class="w-10"
            width="512"
            height="512"
       
          />
          <h3 class="mt-8 mb-4 text-2xl font-semibold text-gray-800 transition dark:text-black">
        Digital Marketing
          </h3>
          <p class="text-black dark:text-black0">
          Search Engine optimization, Email Marketing, Facebook Marketing, Youtube Marketing, Instagram Marketing
          </p>
        </div>
      </div>
    </div>
  </div>

</div>
                                    
  )
}
