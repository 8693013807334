import React from 'react'

export default function Testimonial() {
  return (
    <div>


<section class="text-gray-600 bg-gray-50 body-font">
  <div class="container px-5 py-24 mx-auto">
    <h1 class="text-3xl font-medium title-font text-gray-900 mb-12 text-center">What our customers are saying about us</h1>
    <div data-aos="zoom-in" class="flex flex-wrap -m-4">
      <div class="p-4 md:w-1/2 w-full">
        <div class="h-full bg-gray-100 p-8 rounded">
      
          <p class="leading-relaxed mb-6">“One of the best app developers. I have got made 2 fantastic products from the Kenmerk. It is a vibrant and energetic  team of a couple of youngesters who has great vision. I suggest this team to all who seek a good app builders... Anybody can refer our apps made by this team, PSC Rank Book & Bristu”.</p>
          <a class="inline-flex items-center">
            <img alt="testimonial" swidth="48" height="48" src="https://img.icons8.com/color/48/circled-user-male-skin-type-3--v1.png" class="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"/>
            <span class="flex-grow flex flex-col pl-4">
              <span class="title-font font-medium text-gray-900">Jafar's English</span>
     
            </span>
          </a>
        </div>
      </div>
      <div  data-aos="zoom-in" class="p-4 md:w-1/2 w-full">
        <div class="h-full bg-gray-100 p-8 rounded">
         
          <p class="leading-relaxed mb-6">from last 2 years i was observing the work done by this team, they are improving their quality of work on each project with a significant growth, they are getting well established in the city and adding upon the reputed clients. looking forward for your excellence. best wishes for your upcoming projects.👍🏻</p>
          <a class="inline-flex items-center">
            <img alt="testimonial" width="48" height="48" src="https://img.icons8.com/color/48/circled-user-male-skin-type-3--v1.png" class="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"/>
            <span class="flex-grow flex flex-col pl-4">
              <span class="title-font font-medium text-gray-900">Rohan Manoj</span>
             
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
  )
}
