import React from 'react'
import logo from '../components/assets/KEN ME.png'
export default function Footer() {
  return (
<footer class="bg-black">
  <div class="max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 lg:pt-20 mx-auto">

    <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6">
      <div class="col-span-full lg:col-span-1">
        <a class="flex-none text-xl font-semibold text-white" href="#" aria-label="Brand">KENMERK</a>
      </div>
   

      <div class="col-span-1">
        <h4 class="font-semibold text-gray-100">About</h4>

        <div class="mt-3 grid space-y-3">
          <p><a class="inline-flex gap-x-2 text-gray-400 hover:text-gray-200" href="/Works">Works</a></p>
         
          <p><a class="inline-flex gap-x-2 text-gray-400 hover:text-gray-200" href="#">Careers</a> <span class="inline ml-1 text-xs bg-white text- py-1 px-2 rounded-md">We're hiring</span></p>
        
        </div>
      </div>


      <div class="col-span-1">
        <h4 class="font-semibold text-gray-100">Services</h4>

        <div class="mt-3 grid space-y-3">
          <p><a class="inline-flex gap-x-2 text-gray-400 hover:text-gray-200" href="/Products">Projects</a></p>
 <p><a class="inline-flex gap-x-2 text-gray-400 hover:text-gray-200" href="#">Contact us </a></p>
          {/* <p><a class="inline-flex gap-x-2 text-gray-400 hover:text-gray-200" href="#">Customers</a></p> */}
        </div>
      </div>

      <div class="col-span-2">
        <h4 class="font-semibold text-gray-100">Stay up to date</h4>

        <form>
          <div class="mt-4 flex flex-col items-center gap-2 sm:flex-row sm:gap-3 bg-white rounded-md p-2">
            <div class="w-full">
              <label for="hero-input" class="sr-only">Search</label>
              <input type="text" id="hero-input" name="hero-input" class="py-3 px-4 block w-full border-transparent shadow-sm rounded-md focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Enter your email"/>
            </div>
            <a class="w-full sm:w-auto whitespace-nowrap inline-flex justify-center items-center gap-x-3 text-center bg-blue-600 hover:bg-blue-700 border border-transparent text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4" href="#">
              Subscribe
            </a>
          </div>
          <p class="mt-3 text-sm text-gray-400">
         
          </p>
        </form>
      </div>
     
    </div>
   

    <div class="mt-5 sm:mt-12 grid gap-y-2 sm:gap-y-0 sm:flex sm:justify-between sm:items-center">
      <div class="flex justify-between items-center">
        <p class="text-sm text-white">© 2023 Kenmerk Softwares</p>
      </div>
    

    

    </div>
  </div>
</footer>
  )
}
