import React from 'react'
import ab from'./abt.png'
import abb from './website.png'
import AOS from 'aos';
import "aos/dist/aos.css";

export default function About() {
   AOS.init();
  return (
    

<section class="py-10 bg-[#2f89fc] sm:py-16 lg:py-24">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="grid items-center grid-cols-1 lg:grid-cols-2 gap-x-12 xl:gap-x-24 gap-y-12">
          
            <div data-aos="zoom-in-right"class="relative lg:mb-12">
                <img class="absolute -right-0 -bottom-8 xl:-bottom-12 xl:-right-4" src="https://cdn.rareblocks.xyz/collection/celebration/images/content/3/dots-pattern.svg" alt="" />
                <div class="pl-12 pr-6">
                    <img class="relative" src={ab}  alt="" />
                </div>
                  <img class="absolute -right-0 -bottom-8 xl:-bottom-12 xl:-right-4" src="https://cdn.rareblocks.xyz/collection/celebration/images/content/3/dots-pattern.svg" alt="" />
                <div class="absolute left-0 pr-12 bottom-8 xl:bottom-20">
                    
                </div>
            </div>

            <div data-aos="zoom-in-left" class="2xl:pl-16">
              
                <h2 class="text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-5xl lg:leading-tight">Kenmerk Softwares</h2>
                <p class="text-xl leading-relaxed text-white mt-9">
Kenmerk Softwares
Kenmerk Softwares is an End-to-End IT/ITS services and solutions provider.We help our customers to do business better with our industry wide experience,deep technology expertise and comprehensive portfolio of service.We believe in three important factors which are quality,value and time.Our clock function to deliver you the best at the right time and at the right cost</p>
                {/* <p class="mt-6 text-xl leading-relaxed text-gray-900">Velit officia conse duis enim velit mollit. Exercit ation veniam consequat sunt nostrud amet.</p> */}
            
            </div>
        </div>
    </div>
</section>


   
   




  )
}
