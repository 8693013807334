import React, { useState } from 'react';
import logo from './kio (1).svg'
import lock from './assets/lock.svg.webloc'
import  hamburgerMenu from './assets/hum.png'
import close from './assets/close.png'
const Navbar = () => {

    const [toggle,setToggle]=useState(false)
    const handleClick = ()=> setToggle(!toggle)

  return (
    <div className='w-full h-[80px] sticky top-0 z-30  bg-white border-b'>
        <div className='md:max-w-[1480px] max-w-[600px]sticky top-0 z-30  m-auto w-full h-full flex justify-between items-center md:px-0 px-4'>
            {/* <h1 className='ml-3 text-lg font-bold'>St Thomas Church Arinchermala</h1> */}
            <img src={logo} className="h-[70px] w-[150px] ml-2" />
            
            <div className='hidden  gap-y-1 md:flex items-center '>
                <ul className='flex cursor-auto font-semibold   gap-8'>
                 <a href='/'>   <li >Home</li></a>
                   <a href='/About'>   <li>About</li></a>
                   <a href='/Services'>   <li>Services</li></a>
                  <a href='/Products'>    <li>Products</li></a>
                  <a href='/Works'>    <li>Works</li></a>
                 <a href='/Contact'>     <li>Contact</li></a>
                </ul>
            </div>


            <div className='hidden md:flex'>
                {/* <button className='flex justify-between items-center  bg-transparent  px-6 gap-2'>
                  
                    Login
                </button> */}
                <button href='/Contact'  className='px-8 py-3 mr-2 rounded-md bg-[black] text-white font-bold'><a href='/Contact'>Contact Us</a> </button>
            </div>

            <div className='md:hidden'  onClick={handleClick}>
                    <img src={toggle?close:hamburgerMenu} />
            </div>




        </div>

        <div className={toggle?'absolute z-10 p-4  bg-white w-full px-8 md:hidden border-b':'hidden'}>
            <ul>
                    <a href='/'>   <li className='p-4 hover:bg-gray-100' >Home</li></a>
                   <a href='/About'>   <li className='p-4 hover:bg-gray-100'>About</li></a>
                   <a href='/Services'>   <li className='p-4 hover:bg-gray-100'>Services</li></a>
                  <a href='/Products'>    <li className='p-4 hover:bg-gray-100'>Products</li></a>
                  <a href='/Works'>    <li className='p-4 hover:bg-gray-100'> Works</li></a>
                 <a href='/Contact'>     <li className='p-4 hover:bg-gray-100'> Contact</li></a>
                   
                    <div className='flex flex-col my-4 gap-4'>
                        {/* <button className='flex justify-between items-center  bg-transparent  px-6 gap-2'>
                   
                    Login
                </button> */}
               <button href='/Contact'  className='px-8 py-3 mr-2 rounded-md bg-[black] text-white font-bold'>Contact Us </button>
                    </div>
            </ul>
        </div>
        
        
    </div>
  )
}

export default Navbar