import React from 'react'
import img from '../../components/Product art/ELORA.webp'
import AOS from 'aos';
import img1 from '../../components/Product art/elora Don Size.png'
export default function Productelora() {
    AOS.init();
  return (
    <section class="dark:bg-white dark:text-black">
    
	<div class="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
		<div>
			<h2 class="text-3xl font-bold tracki text-center sm:text-5xl dark:text-black">Elora</h2>
            
			
		</div>
        
		<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
			<div>
				<h3 data-aos="zoom-in-left" class="text-2xl font-bold tracki sm:text-3xl dark:text-black">Elora </h3>
				<p  data-aos="zoom-in-left" class="mt-3 text-lg dark:black">We operate with a golden mission to make forever wearable, affordable, and beautiful jewelry. We have created an amazing ecosystem of quality designs that are futuristic and creative. our relationship with you sustains even after the purchase and it extends to all aspects of customer satisfaction.

We have something for everyone. Highly skilled craftsmen create our products. Along with stringent quality control measures which create that perfection in the products we sell.  </p>
				
			</div>
			<div aria-hidden="true" class="mt-10 lg:mt-0">
				<img  data-aos="zoom-in-left" src={img} alt="" class="mx-auto rounded-lg dark:bg-white"/>
			</div>
		</div>
    

		<div>
			


		</div>
        	
	</div>
    
   <br/>
   
   <div class="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px] shadow-xl">
    <div class="w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
    <div class="h-[46px] w-[3px] bg-gray-800 absolute -left-[17px] top-[124px] rounded-l-lg"></div>
    <div class="h-[46px] w-[3px] bg-gray-800 absolute -left-[17px] top-[178px] rounded-l-lg"></div>
    <div class="h-[64px] w-[3px] bg-gray-800 absolute -right-[17px] top-[142px] rounded-r-lg"></div>
    <div class="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-white dark:bg-gray-800">
        <img data-aos="zoom-in-up" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/mockup-2-light.png" class="dark:hidden w-[272px] h-[572px]" alt=""/>
        <img data-aos="zoom-in-up" src={img1} class="hidden dark:block w-[272px] h-[572px]" alt=""/>
    </div>
</div>
<div class=" ">
    <div class="max-w-2xl mx-auto text-black py-10">
        <div class="text-center">
            <h3 class="text-3xl mb-3"> Download App Now </h3>
         
            <div class="flex justify-center my-10">
                <div class="flex items-center border w-auto rounded-lg px-4 py-2 w-52 mx-2">
               <a href='https://play.google.com/store/apps/details?id=com.kenmerk.eloragold&hl=en_IN&gl=US'>   <img src="https://cdn-icons-png.flaticon.com/512/888/888857.png" class="w-7 md:w-8"/></a>  
                    <div class="text-left ml-3">
                      <a href='https://play.google.com/store/apps/details?id=com.kenmerk.eloragold&hl=en_IN&gl=US'> <p class='text-xs text-black'>Download on </p></a> 
                       <a href='https://play.google.com/store/apps/details?id=com.kenmerk.eloragold&hl=en_IN&gl=US'><p class="text-sm md:text-base"> Google Play Store </p></a> 
                    </div>
                </div>
                {/* <div class="flex items-center border w-auto rounded-lg px-4 py-2 w-44 mx-2">
                    <img src="https://cdn-icons-png.flaticon.com/512/888/888841.png" class="w-7 md:w-8"/>
                    <div class="text-left ml-3">
                        <p class='text-xs text-black'>Download on </p>
                        <p class="text-sm md:text-base"> Apple Store </p>
                    </div>
                </div> */}
            </div>
        </div>
       
    </div>
    
</div>
  
</section>
  )
}
